.newsevent-header {
  display: flex;
  padding: 40px 0 0 40px;
  margin: 0 auto 50px 0;
}
.newsevent-header .orient {
  display: flex;
  width: 140px;
  padding: 0 30px 0 0;
}
.newsevent-header h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: #00f539b0;
  font-weight: 400;
  color: white;
  border-radius: 30px;
  padding: 0.4vmax 2.5vmax;
}
hr {
  margin-left: 0;
  width: 150px;
  left: 0;
}
.template-footer {
  display: inline-block;
}
.calendar-temp {
  display: flex;
  justify-content: start;
  align-items: center;
}
.calendar-temp .fa-calendar {
  display: inline;
  font-size: 15px;
  color: #006cff;
}
.calendar-temp p {
  margin: 10px;
  font-size: 10px;
  font-weight: 400;
}
.clock-temp {
  display: flex;
  justify-content: start;
  align-items: center;
}
.clock-temp .fa-clock-o {
  display: inline;
  font-size: 16px;
  color: #006cff;
}
.clock-temp p {
  margin: 0 10px;
  font-size: 10px;
  font-weight: 400;
}
.envelope-temp {
  display: flex;
  justify-content: start;
  align-items: center;
}
.envelope-temp .fa-envelope {
  display: inline;
  font-size: 16px;
  color: #006cff;
  margin: 0 10px 0 0;
}
.envelope-temp p {
  margin: 10px 0;
  font-size: 10px;
  font-weight: 400;
}
.news-event-align1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px auto;
  width: 90%;
}
.news-event-align1 .template-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 10px auto 0;
}
.news-event-align1 .template-1 .tempImg1 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align1 .template-1 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align1 .template-1 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align1 .template-1 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align1 .template-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 0 auto 10px;
}
.news-event-align1 .template-2 .tempImg2 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align1 .template-2 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align1 .template-2 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align1 .template-2 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 40px auto;
  width: 90%;
}
.news-event-align2 .template-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 10px auto 0;
}
.news-event-align2 .template-3 .tempImg3 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align2 .template-3 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align2 .template-3 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align2 .template-3 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align2 .template-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 0 auto 10px;
}
.news-event-align2 .template-4 .tempImg4 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align2 .template-4 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align2 .template-4 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align2 .template-4 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 40px auto;
  width: 90%;
}
.news-event-align3 .template-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 10px auto 0;
}
.news-event-align3 .template-5 .tempImg5 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align3 .template-5 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align3 .template-5 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align3 .template-5 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align3 .template-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 0 auto 10px;
}
.news-event-align3 .template-6 .tempImg6 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align3 .template-6 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align3 .template-6 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align3 .template-6 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0 auto;
  width: 90%;
}
.news-event-align4 .template-7 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 10px auto 0;
}
.news-event-align4 .template-7 .tempImg7 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align4 .template-7 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align4 .template-7 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align4 .template-7 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
.news-event-align4 .template-8 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: auto 0 auto 10px;
}
.news-event-align4 .template-8 .tempImg8 {
  display: inline-block;
  width: 25vw;
  height: 45vh;
}
.news-event-align4 .template-8 .textTemp {
  display: inline;
  text-align: start;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(1, 1, 1, 0.2);
  border-radius: 20px;
  margin-left: -3%;
  width: 18vw;
  height: 50vh;
}
.news-event-align4 .template-8 .textTemp h4 {
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.news-event-align4 .template-8 .textTemp p {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
@media screen and (max-width: 700px) and (min-width: 300px) {
  .body{
    left: 0;
    right: 0;
    margin: 0;
  }
  .newsevent-header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    right: 0;
    margin: 0 0 50px 0;
    padding: 0 ;
  }
  .newsevent-header .orient {
    display: flex;
    width: 80px;
    padding: 20px;
  }
  .newsevent-header h4 {
    display: flex;
    margin-top: 15%;
    text-align: center;
    font-size: 20px;
    border-radius: 30px;
    padding: 0.4vmax 2.4vmax;
  }
  hr {
    width: 180px;
    left: 0;
  }
  .template-footer {
    display: inline-block;
  }

  .calendar-temp .fa-calendar {
    display: inline;
    font-size: 15px;
    color: #006cff;
  }
  .calendar-temp p {
    margin: 14px;
    font-size: 10px;
    font-weight: 400;
  }
  .clock-temp {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .clock-temp .fa-clock-o {
    display: inline;
    font-size: 16px;
    color: #006cff;
  }
  .clock-temp p {
    margin: 0 ;
    font-size: 10px;
    font-weight: 400;
  }
  .envelope-temp {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .envelope-temp .fa-envelope {
    display: inline;
    font-size: 16px;
    color: #006cff;
    margin: 0 10px 0 0;
  }
  .envelope-temp p {
    margin: 10px 0;
    font-size: 10px;
    font-weight: 400;
  }
  .news-event-align1 {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin:0 auto 0 auto;
    width: 90%;
  }
  .news-event-align1 .template-1 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto 15px auto;
  }
  .news-event-align1 .template-1 .tempImg1 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align1 .template-1 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align1 .template-1 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align1 .template-1 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align1 .template-2 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align1 .template-2 .tempImg2 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align1 .template-2 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align1 .template-2 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align1 .template-2 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align2 {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto 0 auto ;
    width: 90%;
  }
  .news-event-align2 .template-3 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align2 .template-3 .tempImg3 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align2 .template-3 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align2 .template-3 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align2 .template-3 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align2 .template-4 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align2 .template-4 .tempImg4 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align2 .template-4 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align2 .template-4 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align2 .template-4 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align3 {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto 0 auto;
    width: 90%;
  }
  .news-event-align3 .template-5 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align3 .template-5 .tempImg5 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align3 .template-5 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align3 .template-5 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align3 .template-5 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align3 .template-6 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align3 .template-6 .tempImg6 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align3 .template-6 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align3 .template-6 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align3 .template-6 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align4 {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto 0 auto;
    width: 90%;
  }
  .news-event-align4 .template-7 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 15px auto;
  }
  .news-event-align4 .template-7 .tempImg7 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align4 .template-7 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align4 .template-7 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align4 .template-7 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
  .news-event-align4 .template-8 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px auto 0 auto;
  }
  .news-event-align4 .template-8 .tempImg8 {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 35vh;
  }
  .news-event-align4 .template-8 .textTemp {
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: auto;
    padding: 10px;
    background-color: white;
    margin-top: -5%;
    width: 75vw;
    height: 60vh;
  }
  .news-event-align4 .template-8 .textTemp h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }
  .news-event-align4 .template-8 .textTemp p {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
  }
}
