  .result-choice-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .result-choice-headline h1 {
    font-size: 35px;
    font-weight: 400;
    margin-top: 50px;
  }
  .result-choices {
    margin-top: 50px;
    display: block;
    text-align: center;
  }
  .plustwo-result {
    display: block;
    margin: 0 auto 40px auto;
    font-size: 45px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    padding: 20px 110px;
    cursor: pointer;
  }
  .plustwo-result:hover {
    background-color: #cbd3ff;
    color: white;
  }
  .Alevel-result {
    display: block;
    margin: 40px auto 0 auto;
    font-size: 45px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    padding: 20px 60px;
    cursor: pointer;
  }
  .Alevel-result:hover {
    background-color: #efb4fd;
    color: white;
  }
  @media screen and (max-width: 700px) and (min-width:300px) {
    .result-choice-headline {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    .result-choice-headline h1 {
      font-size: 23px;
      font-weight: 400;
      margin-top: 50px;
    }
    .result-choices {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
    .plustwo-result {
      display: block;
      margin: 0 auto 40px auto;
      font-size: 25px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      padding: 20px 80px;
      cursor: pointer;
    }
    .plustwo-result:hover {
      background-color: #cbd3ff;
      color: white;
    }

    .Alevel-result {
      display: block;
      margin: 0 auto 40px auto;
      font-size: 25px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      padding: 20px 55px;
      cursor: pointer;
    }
    .Alevel-result:hover {
      background-color: #efb4fd;
      color: white;
    }
  }
  