.Alevel-header {
    display: flex;
    padding: 40px 0 0 40px;
    margin: auto;
  }
  .Alevel-header .university {
    display: flex;
    width: 230px;
    height: 70px;
    margin-top: 30px;
    padding: 0 30px 0 0;
  }
  .Alevel-header h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    background-color: #E63E4E;
    font-weight: 450;
    color: white;
    border-radius: 30px;
    padding: 0.6vmax 3.5vmax;
  }
  .Alevel-result-search {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: auto;
  }
  .Alevel-result-search .left-form-name {
    display: flex;
    margin: auto 40px auto 40px;
  }
  .Alevel-result-search .left-form-name input {
    border-radius: 20px;
    border-style: none;
    padding: 0.5vmax 2vmax;
    outline: none;
    width: 200px;
    height: 30px;
    font-size: 15px;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: #f2f2f2;
  }
  .Alevel-result-search .middle-form-faculty {
    display: flex;
    margin: auto 40px auto 40px;
  }
  .Alevel-result-search .middle-form-faculty input {
    border-radius: 20px;
    border-style: none;
    padding: 0.5vmax 2vmax;
    outline: none;
    width: 200px;
    height: 30px;
    font-size: 15px;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: #f2f2f2;
  }
  .Alevel-result-search .right-form-studentId {
    display: flex;
    margin: auto 40px auto 40px;
  }
  .Alevel-result-search .right-form-studentId input {
    border-radius: 20px;
    border-style: none;
    padding: 0.5vmax 2vmax;
    outline: none;
    width: 200px;
    height: 30px;
    font-size: 15px;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: #f2f2f2;
  }
  .Alevel-search-icon .fa-search {
    font-size: 22px;
    background-color: #E63E4E;
    border-radius: 50%;
    color: white;
  }
  .Alevel-search-button .search-btn{
    display: none;
  }
  .right-side-wrap{
      display: flex;
      justify-content: end;
      align-items: flex-end;
      margin: 100px 50px 0 0;
  }
  .right-side-wrap .Alevel-side-btn {
    display: block;
  }
  .right-side-wrap .Alevel-side-btn .download {
    display: block;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    width: 160px;
    height: 50px;
    border-style: none;
    margin: 20px 0 20px 0 ;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: white;
  }
  .right-side-wrap .Alevel-side-btn .share {
    display:block;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    width: 160px;
    height: 50px;
    border-style: none;
    margin: 20px 0 20px 0 ;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: white;
  }
  .right-side-wrap .Alevel-side-btn .contact {
    display: block;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    width: 160px;
    height: 50px;
    border-style: none;
    margin: 20px 0 20px 0 ;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    background-color: white;
  }
  @media screen and (max-width: 600px) and (min-width: 300px) {
    .Alevel-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    .Alevel-header .university {
      width: 130px;
      height: 45px;
      margin-top: 0;
      padding: 10px ;
    }
    .Alevel-header h4 {
      font-size: 22px;
      font-weight: 450;
      padding: 0.5vmax 3.5vmax;
    }
    .Alevel-result-search {
      display: block;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 20px auto 0 auto;
    }
    .Alevel-result-search .left-form-name {
      display: inline-block;
      margin: 10px 0 10px 0;
    }
    .Alevel-result-search .left-form-name input {
      display: block;
      border-radius: 20px;
      border-style: none;
      padding: 0.5vmax 3vmax;
      width: 250px;
      height: 30px;
      font-size: 13px;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: #f2f2f2;
    }
    .Alevel-result-search .middle-form-faculty {
      display: inline-block;
      margin: 10px 0 10px 0;
    }
    .Alevel-result-search .middle-form-faculty input {
      display: block;
      border-radius: 20px;
      border-style: none;
      padding: 0.5vmax 3vmax;
      width: 250px;
      height: 30px;
      font-size: 13px;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: #f2f2f2;
    }
    .Alevel-result-search .right-form-studentId {
      display: inline-block;
      margin: 10px 0 10px 0;
    }
    .Alevel-result-search .right-form-studentId input {
      display: block;
      border-radius: 20px;
      border-style: none;
      padding: 0.5vmax 3vmax;
      width: 250px;
      height: 30px;
      font-size: 13px;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: #f2f2f2;
    }
    .Alevel-search-icon .fa-search {
      display: none;
    }
    .Alevel-search-button .search-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px auto 0 auto;
      border-radius: 20px;
      border-style: none;
      padding: 0.6vmax 3vmax;
      width: 280px;
      height: 35px;
      font-size: 22px;
      box-shadow: 0 0 2px 0 rgba(8, 8, 8, 0.2);
      cursor: pointer;
      color: white;
      background-color: #E63E4E;
    }
    .right-side-wrap {
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin:  auto;
    }
    .right-side-wrap .Alevel-side-btn {
      display: block;
    }
    .right-side-wrap .Alevel-side-btn .download {
      display: block;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 500;
      width: 160px;
      height: 50px;
      border-style: none;
      margin: 20px 0 20px 0;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: white;
    }
    .right-side-wrap .Alevel-side-btn .share {
      display: block;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 500;
      width: 160px;
      height: 50px;
      border-style: none;
      margin: 20px 0 20px 0;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: white;
    }
    .right-side-wrap .Alevel-side-btn .contact {
      display: block;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 500;
      width: 160px;
      height: 50px;
      border-style: none;
      margin: 20px 0 20px 0;
      box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
      background-color: white;
    }
  }
  