.dialogStyles {
  display: inline-block;
  align-items: center;
  justify-content: end;
  margin: auto;
  position: absolute;
  margin-top: 100px;
  transform: (-50%, -50%);
  width: 15vw;
  height: 30vh;
  border-radius: 20px;
  right: 120px;
  top: 400px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(4, 4, 4, 0.2);
  margin: auto;
  padding: 0.5rem;
}
.dialogStyles .dialogCloseButton {
  display: block;
  justify-content: end;
  margin: auto 0 auto 13vw;
  text-align: end;
  cursor: pointer;
  font-size: 15px;
  background-color: #ffffff;
  outline: none;
  border-style: none;
  border: none;
}
@media screen and (max-width: 700px) and (min-width: 300px) {
  .dialogStyles {
    display: inline-block;
    align-items: center;
    justify-content: end;
    margin: auto;
    position: absolute;
    margin-top: 100px;
    width: 35vw;
    height: 30vh;
    right: 70px;
    top: 1000px;
    margin: auto;
    padding: 0.5rem;
  }
  .dialogStyles .dialogCloseButton {
    display: block;
    justify-content: end;
    margin: auto 0 auto 30vw;
    text-align: end;
    cursor: pointer;
  }
}
