.image-align {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.image-align .orient {
  display: flex;
  vertical-align: top;
  width: 120px;
  margin-top: 50px;
}
.learning-choice-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.learning-choice-headline h1 {
  font-size: 35px;
  font-weight: 400;
  margin-top: 50px;
}
.learning-choices {
  margin-top: 50px;
  display: block;
  text-align: center;
}
.past-paper {
  display: block;
  margin: 0 auto 40px auto;
  font-size: 45px;
  font-weight: 500;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
  border-radius: 50px;
  border-style: none;
  padding: 20px 80px;
  cursor: pointer;
}
.past-paper .fa-paperclip {
  color: #0babff;
}
.past-paper:hover {
  background-color: #cbd3ff;
  color: white;
}
.past-paper:hover .fas {
  color: white;
}
.library-books {
  display: block;
  margin: 40px auto 0 auto;
  font-size: 45px;
  font-weight: 500;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
  border-radius: 50px;
  border-style: none;
  padding: 20px 70px;
  cursor: pointer;
}
.library-books .fa-book {
  color: #e783ff;
}
.library-books:hover {
  background-color: #efb4fd;
  color: white;
}
.library-books:hover .fas {
  color: white;
}
@media screen and (max-width: 700px) and (min-width:300px) {
  .image-align {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .image-align .orient {
    display: flex;
    vertical-align: top;
    width: 100px;
    margin-top: 100px;
  }
  .learning-choice-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .learning-choice-headline h1 {
    font-size: 23px;
    font-weight: 400;
    margin-top: 50px;
  }
  .learning-choices {
    margin-top: 100px;
    display: block;
    text-align: center;
  }
  .past-paper {
    display: block;
    margin: 0 auto 40px auto;
    font-size: 25px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    padding: 20px 60px;
    cursor: pointer;
  }
  .past-paper .fa-paperclip {
    color: #0babff;
  }
  .past-paper:hover {
    background-color: #cbd3ff;
    color: white;
  }
  .past-paper:hover .fas {
    color: white;
  }
  .library-books {
    display: block;
    margin: 0 auto 40px auto;
    font-size: 25px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    padding: 20px 55px;
    cursor: pointer;
  }
  .library-books .fa-book {
    color: #e783ff;
  }
  .library-books:hover {
    background-color: #efb4fd;
    color: white;
  }
  .library-books:hover .fas {
    color: white;
  }
}
