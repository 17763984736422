.signup-formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.signup-form .signup-text {
  font-size: 35px;
  font-weight: 450;
  color: #35db48;
  margin: 20px;
  font-weight: 650;
}
.signup-form .signup-input-field {
  margin: 20px;
  font-size: 12px;
  font-weight: 500;
}
.signup-input-field #email {
  display: block;
  border-radius: 10px;
  border-style: none;
  outline: none;
  background-color: #eaeaea;
  width: 28vw;
  height: 8vh;
  margin: 10px 0 0 0;
  font-size: 15px;
  padding: 0 2vw;
}
.signup-input-field #password {
  display: block;
  border-radius: 10px;
  border-style: none;
  outline: none;
  background-color: #eaeaea;
  width: 28vw;
  height: 8vh;
  margin: 10px 0 0 0;
  font-size: 15px;
  padding: 0 2vw;
}
.signup-input-field #confirmPassword {
  display: block;
  border-radius: 10px;
  border-style: none;
  outline: none;
  background-color: #eaeaea;
  width: 28vw;
  height: 8vh;
  margin: 10px 0 0 0;
  font-size: 15px;
  padding: 0 2vw;
}
.signup-input-field #userName {
  display: block;
  border-radius: 10px;
  border-style: none;
  outline: none;
  background-color: #eaeaea;
  width: 28vw;
  height: 8vh;
  margin: 10px 0 0 0;
  font-size: 15px;
  padding: 0 2vw;
}
.signup-input-field .signup-btn {
  display: block;
  border-radius: 10px;
  border-style: none;
  background-color: #35db48;
  margin-top: 40px;
  color: white;
  font-size: 25px;
  font-weight: 600;
  width: 32vw;
  height: 8vh;
}
.signup-navigate-textbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:40px auto;
}
.signup-formContainer .signup-navigate-textbtn p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(54, 53, 53);
}
.signup-formContainer .signup-navigate-textbtn p span {
  color: #2782FD;
  cursor: pointer;
}
@media screen and (max-width: 700px) and (min-width: 300px) {
  .signup-formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:40px auto;
  }
  .signup-form .signup-text {
    font-size: 35px;
    font-weight: 450;
    margin: 20px;
    font-weight: 650;
  }
  .signup-form .signup-input-field {
    margin: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .signup-input-field #email {
    display: block;
    border-radius: 10px;
    border-style: none;
    outline: none;
    background-color: #EAEAEA;
    width: 70vw;
    height: 6vh;
    margin: 10px 0 0 0;
    font-size: 15px;
    padding: 0 2vw;
  }
  .signup-input-field #password {
    display: block;
    border-radius: 10px;
    border-style: none;
    outline: none;
    background-color: #EAEAEA;
    width: 70vw;
    height: 6vh;
    margin: 10px 0 0 0;
    font-size: 15px;
    padding: 0 2vw;
  }
  .signup-input-field #confirmPassword {
    display: block;
    border-radius: 10px;
    border-style: none;
    outline: none;
    background-color: #EAEAEA;
    width: 70vw;
    height: 6vh;
    margin: 10px 0 0 0;
    font-size: 15px;
    padding: 0 2vw;
  }
  .signup-input-field #userName {
    display: block;
    border-radius: 10px;
    border-style: none;
    outline: none;
    background-color: #EAEAEA;
    width: 70vw;
    height: 6vh;
    margin: 10px 0 0 0;
    font-size: 15px;
    padding: 0 2vw;
  }
  .signup-input-field .signup-btn {
    display: block;
    border-radius: 10px;
    border-style: none;
    margin-top: 40px;
    font-size: 25px;
    font-weight: 600;
    width: 73.5vw;
    height: 6vh;
  }
  .signup-navigate-textbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
  }
  .signup-formContainer .signup-navigate-textbtn p {
    font-size: 16px;
    font-weight: 400;
    color: rgb(54, 53, 53);
  }
  .signup-formContainer .signup-navigate-textbtn p span {
    color: #2782FD;
    cursor: pointer;
  }
}
