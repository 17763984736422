.largeFooter {
    margin-top: 10vmax;
    margin-bottom: 0;
    padding: 2vmax;
    background-color: #297AE6;
    color: white;
    display: flex;
    text-align:justify;
  }
  .leftFooter {
    width: 20%;
    margin-left: 10vmax;
    display: flex;
    flex-direction:column;
    align-items:flex-start;
  }
  .leftFooter a{
    padding: 5px;
  }
  .midFooter {
    width: 20%;
    margin-left: 10vmax;
    display: flex;
    flex-direction:column;
    align-items:flex-start;
  }
  .midFooter a{
    padding: 5px;
  }
  .rightFooter {
    width: 20%;
    margin-left: 10vmax;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .rightFooter h3{
    margin-bottom:0 ;
  }
  .rightFooter p{
    margin-top:0 ;
  }
  .rightFooter p a{
    text-decoration: none;
    color: white;
  }
  .rightFooter p a:hover{
    text-decoration: underline;
  }

  .smallFooter {
    background-color: #1E5EB4;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: end;
    text-align:end;
   padding: 9px;
   font: 300 1vmax "Roboto";
  }
  @media screen and (max-width:700px) and (min-width:300px){
    .largeFooter {
      padding: 1.7vmax;
      display: flex;
      text-align:start;
      justify-content: start;
    }
    .leftFooter {
      width: 100%;
      font-size: 14px;
      margin-left: 0;
      display: flex;
      align-items:flex-start;
      justify-content: start;
    }
    .midFooter {
      width: 100%;
      font-size: 14px;
      margin-left: 0;
      display: flex;
      align-items:flex-start;
      justify-content: start;
    }
    .rightFooter {
      width: 100%;
      font-size: 14px;
      margin-left: 0;
      display: flex;
      align-items: flex-start;
      justify-content: start;
    }
    .rightFooter p{
      margin-top:0 ;
    }
    .rightFooter a{
      margin-top: 0;
    }
    .smallFooter {
      display: flex;
      text-align:justify;
     padding: 8px;
     font: 300 1.7vmax "Roboto";
    } 
  }