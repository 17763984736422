.top-alignment-home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.top-alignment-home .bg-image .orient-college {
  display: block;
  justify-content: start;
  margin-top: 20px;
  width: 52vw;
  height: 95vh;
}
.slideShow {
  display: block;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.slideShow .registration-template {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 42vh;
  margin: auto;
}
.blockquote {
  display: block;
  align-items: center;
  justify-content: start;
}
.blockquote:before {
  font-family: "FontAwesome";
  content: "\f10d";
  font-size: 40px;
  margin: auto;
  color: rgb(0, 0, 0);
}
.blockquote p {
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 600px;
  margin-top: -10px;
  text-align: center;
}
.slideShow .about-us{
  display: block;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 2px solid black;
  background-color: white;
  margin-top: 70px;
  font-weight: 600;
  font-size: 20px;
  width: 15vw;
  height: 6vh;
  border-radius: 8px;
  cursor: pointer;
}
.slideShow button:hover {
  background-color: #297ae6;
  color: white;
  border-style: none;
}
.popUp {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: auto 70px;
  font-size: 13px;
  font-family: Helvetica Neue;
  color: #297ae6;
}
.popUp img {
  width: 40px;
  cursor: pointer;
}
.popUp p {
  display: block;
  color: #191818;
  font-size: 12px;
}
.program {
  display: flex;
}
.academicProgram {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
}
.academicProgram .text-align {
  display: block;
  justify-content: center;
  text-align: center;
  font-size: 60px;
  font-family: Lucida Sans;
}
.academicProgram .container-one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 20px;
}
.academicProgram .container-one .plusTwoProgram {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 40px 0 0;
}
.academicProgram .container-one .plusTwoProgram .plusTwo {
  margin-left: 30px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.academicProgram .container-one .plusTwoProgram .orientLogo {
  margin-top: 25px;
  margin-left: 30px;
  width: 120px;
}
.academicProgram .container-one .plusTwoProgram .header-text {
  font-size: 20px;
  color: #525252;
  margin-left: 30px;
  font-family: Apple Braille;
}
.academicProgram .container-one .plusTwoProgram ul {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 20px;
  margin-top: -20px;
}

.academicProgram .container-one .plusTwoProgram ul li::before {
  content: "\2022";
  margin-bottom: 50px;
  padding-right: 20px;
  color: #EF8A00;
  font-weight: bold;
  margin-left: -1em;
  font-size: 35px;
  width: 100px;
}
.academicProgram .container-one .plusTwoProgram button {
  display: block;
  background-color: #EF8A00;
  border-style: none;
  border-radius: 30px;
  margin: auto 0 auto 20vw;
  width: 12vw;
  height: 6vh;
  color: rgb(250, 248, 248);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.academicProgram .container-one .A_level {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 0 40px;
}

.academicProgram .container-one .A_level h4 {
  margin-left: 30px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.academicProgram .container-one .A_level img {
  margin-left: 30px;
  margin-top: 25px;
  width: 220px;
}
.academicProgram .container-one .A_level p {
  font-size: 20px;
  color: #525252;
  margin-left: 30px;
  font-family: Apple Braille;
}
.academicProgram .container-one .A_level ul {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 20px;
  margin-top: -20px;
}
.academicProgram .container-one .A_level ul li::before {
  content: "\2022";
  margin-bottom: 50px;
  padding-right: 20px;
  color: #e43d4d;
  font-weight: bold;
  margin-left: -1em;
  font-size: 35px;
  width: 100px;
}
.academicProgram .container-one .A_level button {
  display: block;
  background-color: #e43d4d;
  border-style: none;
  border-radius: 30px;
  margin: auto 0 auto 20vw;
  color: rgb(250, 248, 248);
  font-size: 16px;
  font-weight: 600;
  width: 12vw;
  height: 6vh;
  cursor: pointer;
}
.academicProgram .container-two {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20vh 20px;
}
.academicProgram .container-two .bbsProgram {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 40px 0 0;
}
.academicProgram .container-two .bbsProgram .BBS {
  margin-left: 30px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.academicProgram .container-two .bbsProgram .orientLogo {
  margin-top: 25px;
  margin-left: 30px;
  width: 120px;
}
.academicProgram .container-two .bbsProgram .header-text {
  font-size: 20px;
  color: #525252;
  margin-left: 30px;
  font-family: Apple Braille;
}
.academicProgram .container-two .bbsProgram ul {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 20px;
  margin-top: -20px;
}

.academicProgram .container-two .bbsProgram ul li::before {
  content: "\2022";
  margin-bottom: 50px;
  padding-right: 20px;
  color: #1FC32C;
  font-weight: bold;
  margin-left: -1em;
  font-size: 35px;
  width: 100px;
}
.academicProgram .container-two .bbsProgram button {
  display: block;
  background-color: #1FC32C;
  border-style: none;
  border-radius: 30px;
  margin: auto 0 auto 20vw;
  width: 12vw;
  height: 6vh;
  color: rgb(250, 248, 248);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.academicProgram .container-two .babwsProgram {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 0 40px;
}
.academicProgram .container-two .babwsProgram .BABWS {
  margin-left: 30px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.academicProgram .container-two .babwsProgram .orientLogo {
  margin-top: 25px;
  margin-left: 30px;
  width: 120px;
}
.academicProgram .container-two .babwsProgram .header-text {
  font-size: 20px;
  color: #525252;
  margin-left: 30px;
  font-family: Apple Braille;
}
.academicProgram .container-two .babwsProgram ul {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 20px;
  margin-top: -20px;
}

.academicProgram .container-two .babwsProgram ul li::before {
  content: "\2022";
  margin-bottom: 50px;
  padding-right: 20px;
  color: #3E92E6;
  font-weight: bold;
  margin-left: -1em;
  font-size: 35px;
  width: 100px;
}
.academicProgram .container-two .babwsProgram button {
  display: block;
  background-color: #3E92E6;
  border-style: none;
  border-radius: 30px;
  margin: auto 0 auto 20vw;
  width: 12vw;
  height: 6vh;
  color: rgb(250, 248, 248);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.memoriesOfExcellence {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 100px auto auto 0;
}

.memoriesOfExcellence .ex-memories {
  display: block;
  text-align: center;
  font-size: 60px;
  font-family: Lucida Sans;
}
.memoriesOfExcellence .all-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.memoriesOfExcellence .all-images .upDownImage {
  display: block;
}
.memoriesOfExcellence .upDownImage .entertainment {
  display: inline-block;
  width: 26vw;
  height: 37vh;
  margin: auto 10px 10px 0;
}
.memoriesOfExcellence .upDownImage .travels {
  display: block;
  width: 26vw;
  height: 37vh;
  margin: 10px 10px auto 0;
}
.memoriesOfExcellence .study {
  display: inline-block;
  width: 35vw;
  height: 78vh;
  margin: auto 10px auto 10px;
}
.memoriesOfExcellence .basketball {
  display: inline-block;
  width: 25vw;
  height: 78vh;
  margin: auto 0 auto 10px;
}
.collegeBuilding {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 50px;
}
.collegeBuilding .building {
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto 10px auto 0;
  width: 60vw;
  height: 90vh;
}
.collegeBuilding .location {
  display: block;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.collegeBuilding .location .map {
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  width: 36vw;
  height: 45vh;
}
.collegeBuilding .location button {
  display: block;
  justify-content: center;
  margin: 0 auto 40px auto;
  width: 36vw;
  height: 8vh;
  background-color: #12a4ff;
  border-style: none;
  border-radius: 10px;
  color: rgb(250, 248, 248);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.collegeBuilding .location h5 {
  display: block;
  justify-content: center;
  margin: 0 auto 30px auto;
  font-size: 25px;
  color: #0a63cb;
}
.collegeBuilding .location .address {
  display: block;
  justify-content: center;
  margin: 0 auto 20px auto;
  font-size: 20px;
}
.collegeBuilding .location .phone {
  display: block;
  justify-content: center;
  margin: auto;

  font-size: 20px;
}
.talkShow {
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.talkShow h1 {
  display: block;
  font-size: 60px;
  font-family: Lucida Sans;
  text-align: center;
}
.talkShow h1 span {
  color: #1fc32c;
}
.talkShow .youtube-video {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.talkShow .youtube-video .video {
  margin: 0;
  padding: 0;
  width: 90vw;
  height: 90vh;
  border-radius: 20px;
  border: 0;
}
@media screen and (max-width: 700px) and (min-width: 300px) {
  .top-alignment-home {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .top-alignment-home .bg-image .orient-college {
    display: block;
    width: 100%;
  }
  .slideShow {
    display: block;
    margin: auto;
  }
  .slideShow .registration-template {
    display: block;
    width: 90%;
    margin: 30px auto;
  }
  .blockquote:before {
    font-family: "FontAwesome";
    content: "\f10d";
    font-size: 30px;
    margin: auto;
    color: rgb(0, 0, 0);
  }
  .blockquote p {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600px;
    margin-top: -10px;
    text-align: center;
  }
  .slideShow .about-us{
    display: none;
  }
  .popUp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: auto;
    font-size: 11px;
    font-weight: 500;
  }
  .popUp img {
    width: 60px;
    cursor: pointer;
  }
  .popUp p {
    display: block;
    color: #191818;
    font-size: 11px;
  }
  .program {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .academicProgram {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
  }
  .academicProgram .text-align {
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 40px;
  }
  .academicProgram .container-one {
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .academicProgram .container-one .plusTwoProgram {
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    text-align: start;
  }
  .academicProgram .container-one .plusTwoProgram .box1 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-one .plusTwoProgram .box1 .box2 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-one .plusTwoProgram .plusTwo {
    margin-left: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  .academicProgram .container-one .plusTwoProgram .orientLogo {
    margin-top: 25px;
    margin-left: 30px;
    width: 60px;
  }
  .academicProgram .container-one .plusTwoProgram .header-text {
    font-size: 16px;
    color: #525252;
    margin-left: 30px;
  }
  .academicProgram .container-one .plusTwoProgram ul {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-left: 20px;
    margin-top: -20px;
  }

  .academicProgram .container-one .plusTwoProgram ul li::before {
    content: "\2022";
    margin-bottom: 50px;
    color: #EF8A00;
    font-weight: bold;
    margin-left: -1em;
    font-size: 30px;
    width: 50px;
  }
  .academicProgram .container-one .plusTwoProgram button {
    width: 25vw;
    height: 5vh;
    margin: auto 0 auto 55vw;
    font-size: 14px;
    cursor: pointer;
  }
  .academicProgram .container-one .A_level {
    display:inline-block;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    text-align: start;

  }
  .academicProgram .container-one .A_level .box3 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-one .A_level .box3 .box4 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-one .A_level h4 {
    margin-left: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  .academicProgram .container-one .A_level img {
    margin-top: 25px;
    margin-left: 30px;
    width: 140px;
  }
  .academicProgram .container-one .A_level p {
    font-size: 16px;
    color: #525252;
    margin-left: 30px;
  }
  .academicProgram .container-one .A_level ul {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-left: 20px;
    margin-top: -20px;
  }
  .academicProgram .container-one .A_level ul li::before {
    content: "\2022";
    margin-bottom: 50px;
    padding-right: 20px;
    color: #e43d4d;
    font-weight: bold;
    margin-left: -1em;
    font-size: 30px;
    width: 50px;
  }
  .academicProgram .container-one .A_level button {
    width: 25vw;
    height: 5vh;
    margin: auto 0 auto 55vw;
    font-size: 14px;
    cursor: pointer;
  }
  .academicProgram .container-two {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .academicProgram .container-two .bbsProgram {
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    text-align: start;
  }
  .academicProgram .container-two .bbsProgram .box1 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-two .bbsProgram .box1 .box2 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-two .bbsProgram .BBS {
    margin-left: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  .academicProgram .container-two .bbsProgram .orientLogo {
    margin-top: 25px;
    margin-left: 30px;
    width: 60px;
  }
  .academicProgram .container-two .bbsProgram .header-text {
    font-size: 16px;
    color: #525252;
    margin-left: 30px;
  }
  .academicProgram .container-two .bbsProgram ul {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-left: 20px;
    margin-top: -20px;
  }

  .academicProgram .container-two .bbsProgram ul li::before {
    content: "\2022";
    margin-bottom: 50px;
    color: #1fc32c;
    font-weight: bold;
    margin-left: -1em;
    font-size: 30px;
    width: 50px;
  }
  .academicProgram .container-two .bbsProgram button {
    width: 25vw;
    height: 5vh;
    margin: auto 0 auto 55vw;
    font-size: 14px;
    cursor: pointer;
  }
  .academicProgram .container-two .babwsProgram {
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    text-align: start;
  }
  .academicProgram .container-two .babwsProgram .box1 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-two .babwsProgram .box1 .box2 {
    width: 90vw;
    height: 70vh;
  }
  .academicProgram .container-two .babwsProgram .BABWS {
    margin-left: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  .academicProgram .container-two .babwsProgram .orientLogo {
    margin-top: 25px;
    margin-left: 30px;
    width: 60px;
  }
  .academicProgram .container-two .babwsProgram .header-text {
    font-size: 16px;
    color: #525252;
    margin-left: 30px;
  }
  .academicProgram .container-two .babwsProgram ul {
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-left: 20px;
    margin-top: -20px;
  }

  .academicProgram .container-two .babwsProgram ul li::before {
    content: "\2022";
    margin-bottom: 50px;
    color: #3E92E6;
    font-weight: bold;
    margin-left: -1em;
    font-size: 30px;
    width: 50px;
  }
  .academicProgram .container-two .babwsProgram button {
    width: 25vw;
    height: 5vh;
    margin: auto 0 auto 55vw;
    font-size: 14px;
    cursor: pointer;
  }
  .memoriesOfExcellence {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 100px auto auto 0;
  }

  .memoriesOfExcellence .ex-memories {
    display: block;
    text-align: center;
    font-size: 40px;
    font-family: Lucida Sans;
  }
  .memoriesOfExcellence .all-images {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .memoriesOfExcellence .all-images .upDownImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .memoriesOfExcellence .upDownImage .entertainment {
    display: flex;
    width: 45vw;
    height: 30vh;
    border-radius: 20px;
    margin: auto;
  }
  .memoriesOfExcellence .upDownImage .travels {
    display: flex;
    width: 45vw;
    height: 30vh;
    border-radius: 20px;
    margin: auto;
  }
  .memoriesOfExcellence .study {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 40vh;
    margin: 20px auto;
    border-radius: 20px;
  }
  .memoriesOfExcellence .basketball {
    display: none;
  }
  .collegeBuilding {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
  }
  .collegeBuilding .building {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
  .collegeBuilding .location {
    display: block;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .collegeBuilding .location .map {
    display: flex;
    justify-content: center;
    margin: 30px auto 30px auto;
    width: 90%;
  }
  .collegeBuilding .location button {
    display: block;
    justify-content: center;
    margin: 0 auto 40px auto;
    width: 90%;
    cursor: pointer;
  }
  .collegeBuilding .location h5 {
    display: block;
    justify-content: center;
    width: 90%;
    font-size: 18px;
  }
  .collegeBuilding .location .address {
    display: block;
    justify-content: center;
    margin: 0 auto 20px auto;
    width: 90%;
    font-size: 15px;
  }
  .collegeBuilding .location .phone {
    display: block;
    justify-content: center;
    margin: auto;
    width: 90%;
    font-size: 15px;
  }
  .talkShow {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .talkShow h1 {
    display: block;
    font-size: 40px;
    font-family: Lucida Sans;
    text-align: center;
  }
  .talkShow h1 span {
    color: #1fc32c;
  }
  .talkShow .youtube-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .talkShow .youtube-video .video {
    margin: 0;
    padding: 0;
    width: 90vw;
    height: 30vh;
  }
}
