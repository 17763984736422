.academicProgram-choice-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .academicProgram-choice-headline h1 {
    font-size: 35px;
    font-weight: 400;
    margin-top: 30px;
  }
  .academicProgram-choices {
    margin-top: 40px;
    display: block;
    text-align: center;
  }
  .plustwo-academicProgram {
    display: block;
    margin: 0 auto 40px auto;
    font-size: 40px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    width: 30vw;
    height: 10vh;
    cursor: pointer;
  }
  .plustwo-academicProgram:hover {
    background-color: #EF8A00;
    color: white;
  }
  .Alevel-academicProgram {
    display: block;
    margin: 0 auto 40px auto;
    font-size: 40px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    width: 30vw;
    height: 10vh;
    cursor: pointer;
  }
  .Alevel-academicProgram:hover {
    background-color: #E43D4D;
    color: white;
  }
  .BBS-academicProgram{
    display: block;
    margin: 0 auto 40px auto;
    font-size: 40px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    width: 30vw;
    height: 10vh;
    cursor: pointer;
  }
  .BBS-academicProgram:hover {
    background-color: #1FC32C;
    color: white;
  }
  .BABWS-academicProgram{
    display: block;
    margin: 0 auto 0 auto;
    font-size: 40px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
    border-radius: 50px;
    border-style: none;
    width: 30vw;
    height: 10vh;
    cursor: pointer;
  }
  .BABWS-academicProgram:hover {
    background-color: #3E92E6;
    color: white;
  }
  @media screen and (max-width: 700px) and (min-width:300px) {
    .academicProgram-choice-headline{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    .academicProgram-choice-headline h1 {
      font-size: 20px;
      font-weight: 450;
      margin-top: 50px;
    }
    .academicProgram-choices  {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
    .plustwo-academicProgram  {
      display: block;
      margin: 0 auto 20px auto;
      font-size: 16px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      width: 35vw;
      height: 7vh;
      cursor: pointer;
    }

    .Alevel-academicProgram  {
      display: block;
      margin: 0 auto 20px auto;
      font-size: 16px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      width: 35vw;
      height: 7vh;
      cursor: pointer;
    }
    .BBS-academicProgram{
      display: block;
      margin: 0 auto 20px auto;
      font-size: 16px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      width: 35vw;
      height: 7vh;
      cursor: pointer;
    }
    .BABWS-academicProgram{
      display: block;
      margin: 0 auto 0 auto;
      font-size: 16px;
      font-weight: 500;
      background-color: white;
      box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
      border-radius: 50px;
      border-style: none;
      width: 35vw;
      height: 7vh;
      cursor: pointer;
    }

  }
  