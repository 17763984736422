h1 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 36px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3vh auto;
}
.first-paragraph {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 3vh auto;
  width: 70vw;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
}
.first-paragraph p {
  text-align-last: auto;
  font-size: 14px;
  text-align: start;
}
.vision-text {
  justify-content: center;
  align-items: center;
  margin: 3vh auto;
  width: 70vw;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
}
.vision-text p {
  font-size: 14px;
  text-align: start;
}
.mission-text {
  justify-content: center;
  align-items: center;
  margin: 3vh auto;
  padding: 0.5rem 1.5rem;
  width: 70vw;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
}
.mission-text p {
  font-size: 15px;
  text-align: start;
}
.orient-college-choice {
  justify-content: center;
  align-items: center;
  margin: 3vh auto;
  padding: 0.5rem 1.5rem;
  width: 70vw;
  border-radius: 20px;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
}
.orient-college-choice ul {
  font-size: 14px;
  text-align: start;
}
.salient-features {
  justify-content: center;
  align-items: center;
  margin: 3vh auto -5vh auto;
  width: 70vw;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
}
.salient-features ul {
  font-size: 14px;
  text-align: start;
}
