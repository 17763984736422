.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.Image-name-details {
  margin: 20px 30px auto 0;
  display: block;
  align-items: center;
  justify-content: center;
  width: 18vw;
  height: 55vh;
  box-shadow: 0 0 4px 0 rgba(14, 13, 13, 0.2);
  border-radius: 20px;
}
.Image-name-details .userImage {
  display: block;
  justify-content: center;
  margin: 40px auto 30px auto;
  align-self: center;
  border-radius: 50%;
  width: 50%;
}
.Image-name-details p {
  font-size: 15px;
  font-weight: 550;
  font-family: "Helvetica Neue";
  text-align: center;
  display: block;
  justify-content: center;
  margin: 10px auto;
  align-self: center;
}
.profile-container {
  display: block;
}
.exams-result {
  display: flex;
}
.exam-container {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 20px 30px 0 30px;
  width: 12vw;
  height: 25vh;
  box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
  border-radius: 10px;
}
.exam-container .fa-book-open-reader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  color: #20bc9c;
  font-size: 60px;
}
.exam-container p {
  text-align: center;
  font-size: 12px;
  font-weight: 550;
}
.result-container{
  display: block;
  align-items: center;
  justify-content: center;
  margin: 20px 10px 0 10px;
  width: 12vw;
  height: 25vh;
  box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
  border-radius: 10px;
}
.result-container .fa-square-poll-vertical{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  color: #BB3BFF;
  font-size: 60px;
}
.result-container p {
  text-align: center;
  font-size: 12px;
  font-weight: 550;
}
.schedule-others{
  display: flex;
}
.schedule-others .schedule-container{
  display: block;
  align-items: center;
  justify-content: center;
  margin: 20px 30px 0 30px;
  width: 12vw;
  height: 25vh;
  box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
  border-radius: 10px;
}
.schedule-container .fa-calendar-alt{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
  color: #FC2F2F;
  font-size: 60px;
}
.schedule-container p{
  text-align: center;
  font-size: 12px;
  font-weight: 550;
}
.news-elearning-report{
  display:block;
  align-items: center;
  justify-content: center;
  margin: 20px 10px 0 10px;
}
.news-elearning-report .news-events{
  display: block;
  background-color: #00d933be;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 12vw;
  height: 7vh;
  cursor: pointer;
  border-radius: 10px;
  border-style: none;
  outline: none;
}
.news-elearning-report .e-learning{
  display: block;
  background-color: #4D84FF;
  color: white;
  margin-top: 9px;
  margin-bottom: 9px;
  font-weight: 600;
  font-size: 14px;
  width: 12vw;
  height: 7vh;
  cursor: pointer;
  border-radius: 10px;
  border-style: none;
  outline: none;
}
.news-elearning-report .report{
  display: block;
  background-color: #3C3C3C;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 12vw;
  height: 7vh;
  cursor: pointer;
  border-radius: 10px;
  border-style: none;
  outline: none;
}
.academic-progress{
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 50vw;
  padding: 0.2rem 1rem;
  margin:20px auto;
  box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
}
.academic-progress h4{
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.academic-progress p{
  color: #656565;
  font-size: 12px;
  font-weight: 550;
}
.academic-progress .academic-progress-chart{
  width: 50vw;
}
.individual-progress{
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 50vw;
  padding: 0.2rem 1rem;
  margin:20px auto;
  box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
}
.individual-progress h4{
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.individual-progress p{
  color: #656565;
  font-size: 12px;
  font-weight: 550;
}
.individual-progress .individual-progress-chart{
  width: 50vw;
}
.signout-button {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  font-weight: 550;
  background-color: #2C2C2C;
  width: 53vw;
  height: 6vh;
  outline: none;
  border-style: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  color: white;
}
@media screen and (max-width:700px) and (min-width:300px) {
  .profile {
    display: block;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .Image-name-details {
    margin:20px auto ;
    display: block;
    align-items: center;
    justify-content: center;
    width: 85vw;
    height: 85vh;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
    border-radius: 20px;
  }
  .Image-name-details .userImage {
    display: inline-block;
    background-color: #20bc9c;
    justify-content: center;
    margin:10vh 25vw 0 25vw;
    align-self: center;
    border-radius: 50%;
    width: 40%;
  }
  .Image-name-details p {
    font-size: 14px;
    font-weight: 550;
    text-align: center;
    display: block;
    justify-content: center;
    margin: 30px auto;
    align-self: center;
  }
  .profile-container {
    display: block;
  }
  .exams-result {
    display: flex;
  }
  .exam-container {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 20px auto 20px auto;
    width: 38vw;
    height: 25vh;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
    border-radius: 10px;
  }
  .exam-container .fa-book-open-reader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
    color: #20bc9c;
    font-size: 60px;
  }
  .exam-container p {
    text-align: center;
    font-size: 15px;
    font-weight: 550;
  }
  .result-container{
    display: block;
    align-items: center;
    justify-content: center;
    margin: 20px auto 20px auto;
    width: 38vw;
    height: 25vh;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
    border-radius: 10px;
  }
  .result-container .fa-square-poll-vertical{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
    color: #BB3BFF;
    font-size: 60px;
  }
  .result-container p {
    text-align: center;
    font-size: 15px;
    font-weight: 550;
  }
  .schedule-others{
    display: flex;
  }
  .schedule-others .schedule-container{
    display: block;
    align-items: center;
    justify-content: center;
    margin: 20px auto 20px auto;
    width: 38vw;
    height: 25vh;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
    border-radius: 10px;
  }
  .schedule-container .fa-calendar-alt{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
    color: #FC2F2F;
    font-size: 60px;
  }
  .schedule-container p{
    text-align: center;
    font-size: 15px;
    font-weight: 550;
  }
  .news-elearning-report{
    display:block;
    align-items: center;
    justify-content: center;
    margin: 20px auto 20px auto;
  }
  .news-elearning-report .news-events{
    display: block;
    background-color: #00d933be;
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 38vw;
    height: 7vh;
    cursor: pointer;
    border-radius: 10px;
    border-style: none;
    outline: none;
  }
  .news-elearning-report .e-learning{
    display: block;
    background-color: #4D84FF;
    color: white;
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    width: 38vw;
    height: 7vh;
    cursor: pointer;
    border-radius: 10px;
    border-style: none;
    outline: none;
  }
  .news-elearning-report .report{
    display: block;
    background-color: #3C3C3C;
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 38vw;
    height: 7vh;
    cursor: pointer;
    border-radius: 10px;
    border-style: none;
    outline: none;
  }
  .academic-progress{
    display: block;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 80vw;
    padding: 0.2rem 1rem;
    margin:20px auto;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
  }
  .academic-progress h4{
    color: #212121;
    font-size: 15px;
    font-weight: 500;
  }
  .academic-progress p{
    color: #656565;
    font-size: 10px;
    font-weight: 550;
  }
  .academic-progress .academic-progress-chart{
    width: 80vw;
  }
  .individual-progress{
    display: block;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 80vw;
    padding: 0.2rem 1rem;
    margin:20px auto;
    box-shadow: 0 0 8px 0 rgba(14, 13, 13, 0.2);
  }
  .individual-progress h4{
    color: #212121;
    font-size: 15px;
    font-weight: 500;
  }
  .individual-progress p{
    color: #656565;
    font-size: 10px;
    font-weight: 550;
  }
  .individual-progress .individual-progress-chart{
    width: 80vw;
  }
  .signout-button {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    font-weight: 550;
    background-color: #2C2C2C;
    width: 85vw;
    height: 5vh;
    outline: none;
    border-style: none;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
    color: white;
  }

}